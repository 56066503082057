// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";
import "slick-carousel";

// custom

jQuery(document).on("ready", function () {

    if (document.documentMode === 10) {
        document.documentElement.className += " ie10";
    }
    else if (document.documentMode === 11) {
        document.documentElement.className += " ie11";
    }

    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        jQuery("body").addClass("mac-os");
    }
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
            jQuery("body").addClass("chrome");
        } else {
            jQuery("body").addClass("safari");
        }
    }
});

// jQuery(".menu-toggle").on("click", function () {
//     jQuery("html").toggleClass("nav-open");
//     console.log("menu toggle opened");
// });

// jQuery(".close-icon").on("click touchstart", function () {
//     jQuery("html").removeClass("nav-open");
// });

// jQuery(".menu-toggle").on("click", function () {
//     jQuery("body").toggleClass("menu-open");
// });
// jQuery(".overlay").on("click touchstart", function () {
//     jQuery("body").removeClass("menu-open");
//     jQuery(".header-menu ul li").removeClass("open");
// });
// jQuery(".close-icon").on("click touchstart", function () {
//     jQuery("body").removeClass("menu-open");
//     jQuery(".header-menu ul li").removeClass("open");
// });

jQuery(".menu-item-has-children").on("touch-end", function (e) {
    if (!jQuery(this).hasClass("menu-open")) {
        jQuery(this).addClass("menu-open");
        e.preventDefault;
    }
});



function close_all(type, itemClass) {
    type.forEach((ele) => {
        ele.parentElement.classList.remove(itemClass);
    });
}
const dropdowns = document.querySelectorAll(".main-navigation .menu-item-has-children > a");

// custom
document.querySelectorAll(".close-icon, .overlay, .menu-toggle").forEach((element) => {
    element.addEventListener("click", function () {
        const body = document.querySelector("body");
        if (body.classList.contains("menu-open")) {
            body.classList.remove("menu-open");
        } else {
            body.classList.add("menu-open");
        }

        close_all(dropdowns, "sub-menu-open");
    });
});

dropdowns.forEach((element) => {
    element.addEventListener("click", function (e) {
        if (window.outerWidth < 991) {

            const elementState = element.parentElement.classList.contains("sub-menu-open");

            // for each dropdown item, remove the "sub-menu-open" class
            close_all(dropdowns, "sub-menu-open");

            // if the currently clicked link is contained by a .menu-item-has-children:not(.sub-menu-open)
            if (!elementState) {
                e.preventDefault();

                // hook in to the li wrapping the element we just clicked
                let elementTree = element.parentElement;

                // while we're inside of the menu:
                // if the element we are on contains EITHER "menu-item-has-children" or "sub-menu"
                while (elementTree.classList.contains("menu-item-has-children") || elementTree.classList.contains("sub-menu")) {

                    // if the menu item is a wrapper for a sub-menu, add the sub-menu-open class
                    if (elementTree.classList.contains("menu-item-has-children")) {
                        elementTree.classList.add("sub-menu-open");
                    }
                    elementTree = elementTree.parentElement;
                }
            }
        }
    });
});
